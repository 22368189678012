<template>
    <b-navbar type="is-navbar-main navbar-default" is-active centered>
        <template slot="brand">
            <a class="navbar-item" title="Abrir o menu" :class="{ 'is-hidden': sidebarIsOpened }" @click="SET_SIDEBAR(true)">
                <b-icon icon="menu"></b-icon>
            </a>

            <a class="navbar-item is-hidden-mobile" @click="$router.push(`/`)">
                <img v-if="config.logoBase64" :src="'data:image/png;base64, ' + config.logoBase64" height="20" width="80" />                
            </a>
        </template>

        <template slot="end">

            <div v-if="config.logoffTimer && !isDashboard" class="navbar-item" id="navIdleProgressBar">
                <b-progress :value="idleProgressBarValue" :type="idleProgressBarType" show-value>
                    <small>{{ idleRemainingTime }}</small>
                </b-progress>
            </div>

            <a class="navbar-link is-arrowless" @click="TOGGLE_MODAL_NOTIFICACAO">
                <b-icon icon="bell"></b-icon>
                <b-tag v-if="totalNotificacao > 0" type="is-danger tag-notify" rounded>{{ totalNotificacao }}</b-tag>
            </a>            

            <a class="navbar-link is-arrowless" @click="TOGGLE_MODAL_USUARIO">
                <b-icon icon="account"></b-icon>
            </a>

            <b-modal :active.sync="modalNotificacaoIsOpened" has-modal-card trap-focus aria-role="dialog" aria-modal>
                <modal-notificacao></modal-notificacao>
            </b-modal>            

            <b-modal :active.sync="modalUsuarioIsOpened" has-modal-card trap-focus aria-role="dialog" aria-modal>
                <modal-usuario></modal-usuario>
            </b-modal>
        </template>
    </b-navbar>
</template>

<script>
    import { mapState } from 'vuex'
    import { mapMutations } from 'vuex'
    import modalUsuario from '@/components/modalUsuario'
    import modalNotificacao from '@/components/modalNotificacao.vue'

    export default {
               
        components: {
            modalUsuario,
            modalNotificacao
        },
        mounted() {
            this.loadTotalNotificacoesFuncionario();              
        },        

        computed: {
          
            idleRemainingTime() {
                const totalSeconds = (this.$store.state.config.logoffMiliseconds - this.$store.state.idle.time) / 1000;

                const minutes = Math.floor(totalSeconds / 60);
                const seconds = Math.floor(totalSeconds % 3600 % 60);

                return minutes + ":" +
                    (seconds < 10 ? "0" : "") + seconds;
            },
            idleProgressBarValue() {
                const value = ((this.$store.state.config.logoffMiliseconds - this.$store.state.idle.time) / this.$store.state.config.logoffMiliseconds) * 100;
                return isNaN(value)
                    ? 100
                    : value;
            },
            idleProgressBarType() {
                return this.idleProgressBarValue > 66
                    ? "is-info"
                    : this.idleProgressBarValue > 33
                        ? "is-warning"
                        : "is-danger";
            },
            notificationsCount() {
                return 10;
            },            
            isDashboard(){
                return this.$route.name == 'exames-entregas' || this.$route.name == 'progresso-setor'
            },
            modalNotificacaoIsOpened: {
                get() {
                    return this.$store.state.componentes.modalNotificacaoIsOpened;
                },
                set() {
                    return this.$store.commit('componentes/TOGGLE_MODAL_NOTIFICACAO')
                }
            },  
            totalNotificacao:{
                get() {
                    return this.$store.state.componentes.totalNotificacao;
                },
                set() {
                    return this.$store.commit('componentes/SET_TOTAL_NOTIFICACAO',0)
                }                

            },         
            modalUsuarioIsOpened: {
                get() {
                    return this.$store.state.componentes.modalUsuarioIsOpened;
                },
                set() {
                    return this.$store.commit('componentes/TOGGLE_MODAL_USUARIO')
                }
            },
            ...mapState([
                'config',
                'usuario'
            ]),
            ...mapState('componentes', [
                'sidebarIsOpened'
            ])
        },
        methods: {
            loadTotalNotificacoesFuncionario() {
                
                this.$http.get('/api/notificacao/retornaTotalNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                        this.$store.commit('componentes/SET_TOTAL_NOTIFICACAO',data)
                    })
                    .catch((error) => {                        
                        throw error
                    });
                
            },             
            ...mapMutations('componentes', [
                'SET_SIDEBAR',
                'TOGGLE_MODAL_USUARIO',
                'TOGGLE_MODAL_NOTIFICACAO',
                'SET_TOTAL_NOTIFICACAO',
            ]),

        }
    }
</script>