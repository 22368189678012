
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Sobre from '../views/sobre.vue'


/*MENUS*/ 
import manutencao from '../views/dashboard/manutencao.vue'
import relatorio from '../views/dashboard/relatorio.vue'
import acompanhamento from '../views/dashboard/acompanhamento.vue'
import custofabrica from '../views/dashboard/custofabrica.vue'


/*MENU MANUTENÇÃO*/ 
import niveldeacesso from '../views/cadastro/niveldeacesso.vue'
import unidade from '../views/cadastro/unidade.vue'
import funcionarioVue from '../views/cadastro/funcionario.vue'

import niveisDeAcessoVue from '../views/lista/niveisdeacesso.vue'
import unidadesVue from '../views/lista/unidades.vue'
import funcionariosVue from '../views/lista/funcionarios.vue'


/*MENU RELATORIO*/ 
import notificacaoRelVue from '../views/relatorio/notificacao.vue'
import logsRelVue from '../views/relatorio/logs.vue'
import clientesRelVue from '../views/relatorio/clientes.vue'
import funcionariosRelVue from '../views/relatorio/funcionarios.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Sobre',
		component: Sobre,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	
	{
		name: 'niveldeacesso',
		path: '/manutencao/niveldeacesso',
		component: niveldeacesso,
		meta: {
			roles: ["Funcionario"]
		}
	},
	
	{
		name: 'unidade',
		path: '/manutencao/unidade',
		component: unidade,
		meta: {
			roles: ["Funcionario"]
		}
	},
	

	
	{
		name: 'niveisdeacesso',
		path: '/manutencao/niveisdeacesso',
		component: niveisDeAcessoVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	
	{
		name: 'unidades',
		path: '/manutencao/unidades',
		component: unidadesVue,
		meta: {
			roles: ["Funcionario"]
		}
	},

	
	{
		name: 'funcionarios',
		path: '/manutencao/funcionarios',
		component: funcionariosVue,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'funcionario',
		path: '/manutencao/funcionario',
		component: funcionarioVue,
		meta: {
			roles: ["Funcionario"]
		}
	},

	{
		name: 'manutencao',
		path: '/manutencao',
		component: manutencao,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'acompanhamento',
		path: '/acompanhamento',
		component: acompanhamento,
		meta: {
			roles: ["Funcionario"]
		}
	},
	{
		name: 'custofabrica',
		path: '/custofabrica',
		component: custofabrica,
		meta: {
			roles: ["Funcionario"]
		}
	},			
	
	{
		name: 'relatorio',
		path: '/relatorio',
		component: relatorio,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'notificacao',
		path: '/relatorio/notificacao',
		component: notificacaoRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'logs',
		path: '/relatorio/logs',
		component: logsRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'clientesRel',
		path: '/relatorio/clientes',
		component: clientesRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},	
	{
		name: 'funcionariosRel',
		path: '/relatorio/funcionarios',
		component: funcionariosRelVue,
		meta: {
			roles: ["Funcionario"]
		}
	},					

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'is-active'
})

const storeInit = store.dispatch('init')

router.beforeEach(async (to, from, next) => {
	await storeInit.then(function() {

		if(store.state.usuario.id != undefined){	
			if(store.state.usuario.tipos[0] == "Funcionario"){
				if (to.matched.some(record => record.meta.roles.filter(role => role == "Funcionario").length > 0)) {
					next()
				} else {
					next("/")
				}
			}
		}
		else {
			next()
		}
	})
    .catch(e => {
		console.error(e);
    })

})

export default router
