<template>
  <section>
    <titlebar :title-stack="titleStack"></titlebar>
    <div class="columns box">
      <div class="column is-3">
        <b-field>
          <b-input
            :placeholder="$t('FUNCIONARIOLISTA.PROCURARNOME')"
            v-model="procurar"
            icon="magnify"
            @input="loadAsyncData"
          ></b-input>
        </b-field>
      </div>




    </div>
    <b-table
      :data="model.lista"
      :loading="loading"
      paginated
      striped
      hoverable
      backend-pagination
      :total="total"
      @page-change="onPageChange"
      :aria-next-label="$t('SISTEMA.PROXPAGINA')"
      :aria-previous-label="$t('SISTEMA.PAGANTERIOR')"
      :aria-page-label="$t('SISTEMA.PAGINA')"
      :aria-current-label="$t('SISTEMA.PAGATUAL')"
      backend-sorting
      :default-sort-direction="defaultOrdenar"
      :default-sort="[ordenarPor, ordenar]"
      sort-icon-size="is-small"
      @sort="onSort"
    >
      <template  >
        <b-table-column v-slot="props" field="id" :label="$t('SISTEMA.ID')">
          {{ props.row.id }}
            <b-icon v-if="props.row.desativado"
                            icon="close-circle"
                            size="is-small"                                    
                            :title="$t('FUNCIONARIOLISTA.DESATIVADO')"
                            type="is-danger"></b-icon>                
        </b-table-column>

        <b-table-column v-slot="props" field="Nome" :label="$t('SISTEMA.NOME')">
          {{ props.row.nome }}
        </b-table-column>

        <b-table-column v-slot="props" field="NivelAcesso" :label="$t('FUNCIONARIOLISTA.NIVELACESSO')">
          {{ props.row.nivelAcesso }}
        </b-table-column>       

        <b-table-column v-slot="props" field="Email" :label="$t('FUNCIONARIOLISTA.EMAIL')">
          {{ props.row.email }}
        </b-table-column>              

        <b-table-column v-slot="props" custom-key="actions" numeric>
          <router-link
            :to="{ name: 'funcionario', params: { id: props.row.id } }"
            class="button is-small is-primary"
            :title="$t('SISTEMA.EDITAR')"
          >
            <b-icon icon="pencil" size="is-small"></b-icon>
          </router-link>
        </b-table-column>          

      </template>
    </b-table>
  </section>
</template>

<script>
import { mapGetters,mapState } from "vuex";
import titlebar from "@/components/titlebar.vue";

export default {
  data() {
    return {
      model: [],
      total: 0,
      loading: false,
      procurar: this.$route.query.procurar ?? "",
      ordenarPor: "Nome",
      ordenar: "asc",
      defaultOrdenar: "asc",
      page: 1,
      perPage: 20,
      selecionado: null,
      id: null,
      statusFiltro: "Ativo",
      niveis: [],
      unidadesDeAcesso: [],     
    };
  },
  components: {
    titlebar,
  },
  computed: {
      ...mapState([                                
          'unidadeId',                
      ]),     
    titleStack() {
      return [this.$t('SISTEMA.MANUTENCAO'), 
      this.$t('FUNCIONARIOLISTA.FUNCIONARIO')];
    },
    ...mapGetters(["isInRole"]),
  },
  watch: {            
      unidadeId: function(novaUnidade) {                 
          this.loadAsyncData();                                                     
      },
  },     
  methods: {

 
    loadAsyncData() {
      const params = [
        `procurar=${this.procurar}`,
        `ordem=${this.ordenarPor}.${this.ordenar}`,
        `page=${this.page}`,        
        `statusFiltro=${this.statusFiltro}`,                
      ];

      this.loading = true;
      this.$http
        .get(`/api/manutencao/funcionarios?${params.join("&")}`)
        .then(({ data }) => {
          this.model = data;
          if (data.lista.length == 0 && this.page > 1) {
            this.page = 1;
            this.loadAsyncData();
          }
          this.total = this.perPage * data.pageCount;
          this.loading = false;
          this.$router.replace({
            query: { procurar: this.procurar, page: this.page },
          });
        })
        .catch((error) => {
          this.model = [];
          this.total = 0;
          this.loading = false;
          throw error;
        });
    },
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    onSort(field, order) {
      this.ordenarPor = field;
      this.ordenar = order;
      this.loadAsyncData();
    },

  },
  mounted() {
    this.loadAsyncData();    
  },
};
</script>
