<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-info">
            <p class="modal-card-title">{{$t('MODALOBSERVACAOGUIA.OBSERVACAO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">

                <p class="panel-block">
                    <b-field :label="$t('MODALOBSERVACAOGUIA.NOTIFICARENVOLVIDOS')">
                    </b-field>
                </p>                
                <div class="panel-block">                                    
                    
                    <div >
                        <searchIdName class="column is-9" :id.sync="adicionaFuncionarioId" :nome.sync="adicionaFuncionarioNome" :trazDesativado="false" 
                                        :label="$t('SISTEMA.FUNCIONARIO')" table="FuncionariosUnidade"  :camposAdicionais="'&codigoEmpresa='+codigoEmpresa+'&codigoLoja='+codigoLoja" >
                                        <slot>
                                                <b-button icon-left="plus" type="is-primary" @click="addFuncionario()"></b-button>
                                            </slot>                                        
                        </searchIdName>
                        <table class="table is-fullwidth">
                            <tr  v-for="(item, index) in funcionarios" v-bind:key="index">
                                <td>{{item.nome}} </td>                                                                          
                                <td><b-button type="is-danger" size="is-small" @click="deleteFuncionario(index)" title="Remover Funcionario?" > <b-icon icon="delete" size="is-small"></b-icon> </b-button> </td>   
                            </tr>
                        </table> 
                    </div>                      
                </div>
                                
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="incluirObservacao">{{$t('SISTEMA.INCLUIR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    import searchIdName from '@/components/searchIdName.vue'      
    
    export default {
        props: {
            assistenciaId: Number,
            codigoLoja:Number,
            codigoEmpresa: Number, 
            guiaId: Number                                   
        },
        data() {
            return {                              
                observacoes: null,
                salvandoDados :false,                
                funcionarios:[],
                adicionaFuncionarioId:null,
                adicionaFuncionarioNome:null
            }
        },
        components: {                       
            searchIdName                               
        },        
        computed: {
            ...mapState([
                'unidadeId',                                                         
                'usuario', 
            ]),
        },        

        mounted() {
            return true;
        },
        methods: {

            addFuncionario(){

                if(this.adicionaFuncionarioNome){
                    this.funcionarios.push({id: this.adicionaFuncionarioId, nome: this.adicionaFuncionarioNome})
                    this.adicionaFuncionarioNome = null;
                    this.adicionaFuncionarioId = '';            
                    this.$forceUpdate();  
                }


            },
            deleteFuncionario(index) {
                
                this.funcionarios.splice(index, 1);
                                
                this.$forceUpdate();                
            },                     

            async incluirObservacao(){

                this.salvandoDados=true;
                

                if (this.observacoes== null  || this.observacoes == '' || this.observacoes == undefined) {
                    this.salvandoDados = false;
                    return;
                }      
                
                const mensagemNotificacao = {
                    guiaId: this.guiaId,
                    mensagem : this.observacoes,                    
                    funcionariosEnvolvidos : this.funcionarios,
                    codigoEmpresa : this.codigoEmpresa,
                    codigoLoja: this.codigoLoja                   
                }                
                this.isLoading = true;  
                
                try {
                    this.$http.post('/api/dashboard/EnviaMensagem', mensagemNotificacao)
                        .then(res => {
                            this.salvandoDados=false;
                            return true;

                        }).finally(() => {                                
                            this.salvandoDados=false;    
                            this.observacoes = null;

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Mensagem enviada com sucesso",
                                type: 'is-success'
                            }); 
                                                                                                                  
                            this.$emit('close');                                                                                   
                        }); 
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }                    
            },            
            
        }
    }
</script>