import IdleJs from 'idle-js'

export default {
    install(Vue, options) {
        const {
            router,
            store,
            moduleName = 'idle',
            idleTime = 1000,
            events = ['mousemove', 'keydown', 'mousedown', 'touchstart'],
            keepTracking = true,
            startAtIdle = false
        } = options || {}

        if (!store) {
            throw Error('`store` must be passed in options')
        }

        store && store.registerModule(moduleName, {
            state: {
                isIdle: startAtIdle,
                time: 0
            },
            mutations: {
                [`${moduleName}/IDLE_CHANGED`]: function (state, isIdle) {
                    state.isIdle = isIdle
                },
                [`${moduleName}/INCREASE_TIME`]: function (state) {
                    state.time += 1000;
                },
                [`${moduleName}/RESTART_TIME`]: function (state) {
                    state.isIdle = false;
                    state.time = 0;
                }
            }
        })

        const idle = new IdleJs({
            idle: idleTime,
            events,
            keepTracking,
            startAtIdle,

            onIdle() {
                const telasNaoDeslogar = [
                    'totendeatendimento',
                    'paineldeatendimento',
                    'exames-entregas',
                    'progresso-setor'
                ];

                store && store.commit(`${moduleName}/IDLE_CHANGED`, true);
                const timerIdle = setInterval(() => {
                    if (!store.state[moduleName].isIdle) clearInterval(timerIdle);

                    store.commit(`${moduleName}/INCREASE_TIME`);
                    if (store.state[moduleName].time >= store.state.config.logoffMiliseconds){

                        clearInterval(timerIdle);

                        //não contar o tempo nas telas indicadas
                        if(telasNaoDeslogar.indexOf(router.history.current.name) == -1) {
                            store.commit('ADD_ERRO', 'Desconectado por tempo ocioso.', { root: true })
                            store.dispatch('logout', { root: true })
                        }
                        else {
                            store.commit(`${moduleName}/RESTART_TIME`)
                        }
                    }
                }, 1000);
            },
            onActive() {
                store && store.commit(`${moduleName}/RESTART_TIME`);
            }
        })

        Vue.prototype.$idle = idle;
    }
}