<template>
    <section class="section is-title-bar">
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <ul>
                        <li v-for="(title,index) in titleStack" :key="index">{{ title }}</li>
                    </ul>
                </div>
            </div>
            <div class="level-right" v-if="hasRightSlot">
                <div class="level-item">
                    <slot name="right" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'TitleBar',
        props: {
            titleStack: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            hasRightSlot() {
                return !!this.$scopedSlots.right
            }
        }
    }
</script>