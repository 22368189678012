<template>
    <transition name="fade">
        <div class="column is-2 is-fullheight sidebar-default" v-if="sidebarIsOpened">
            <aside class="menu">
                <p class="menu-label">
                    <button class="delete is-small is-pulled-right" id="close-sidebar" aria-label="close" @click="SET_SIDEBAR(false)" title="Fechar o menu"></button>
                </p>
                <figure class="image is-64x64 has-image-centered is-hidden-mobile">
                    <img v-if="foto==null" class="is-rounded" src="../assets/pessoa.png">
                    <img v-else class="is-rounded" :src="foto">
                </figure>
                <h4 class="title is-6 has-text-centered is-marginless">
                    {{ usuario.nome }}
                </h4>
                <p class="is-size-7 has-text-centered">
                    {{ usuario.nivel }}
                </p>
           
                <hr class="dropdown-divider">


                <ul class="menu-list">                 
                 
                    <li >
                        <router-link v-if="isInRole('relatorio-acompanhamento')"  @click.native="HANDLE_RESIZE()" :to="{ name: 'acompanhamento' }"> {{$t('SISTEMA.ACOMPANHAMENNTO')}}</router-link>
                        <router-link v-if="isInRole('relatorio-assistencias-custo-fabrica')" @click.native="HANDLE_RESIZE()" :to="{ name: 'custofabrica' }"> {{$t('SISTEMA.CUSTOFABRICA')}}</router-link>                        
                        <router-link v-if="isInRole('menu-cadastros')" @click.native="HANDLE_RESIZE()" :to="{ name: 'manutencao' }"> {{$t('SISTEMA.MANUTENCAO')}}</router-link>
                        
                    </li>             
                    
                </ul>
                <hr class="dropdown-divider">
                <ul class="menu-list">
                    <li>
                        <a @click="logout">
                            <i class="fas fa-sign-out-alt"></i>
                            {{$t('SISTEMA.SAIR')}}
                        </a>
                    </li>
                </ul>
            </aside>
        </div>
    </transition>    
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex'
    import { mapMutations } from 'vuex'

    export default {
        data() {
            return {
                alterandoUnidade: false,
                foto: null,       
            }
        },
        computed: {
            ...mapState([
                'config',
                'usuario',
                'unidadeId',
                'unidades'
            ]),
            ...mapState('componentes', [
                'sidebarIsOpened'
            ]),
            ...mapGetters([
                'isInRole'
            ]),
            unidadeId: {
                get() {
                    return this.$store.state.unidadeId;
                },
                set(value) {
                    this.alterandoUnidade = false;
                    this.$store.commit('SET_UNIDADE', value);
                }
            },
            unidadeNome() {
                const unidade = this.unidades?.find(x => x.id == this.unidadeId);
                return unidade?.nome;
            },
        },
        created(){
            this.getImage();
        },
        methods: {
            getImage() {
                if (!this.foto){                    
                    this.foto = '/api/manutencao/funcionarioFoto?id=' + this.usuario.funcionarioId +`&_cl=${new Date().getTime()}`;
                }

                return this.foto;
            },            
            ...mapActions([
                'logout'
            ]),
            ...mapMutations('componentes', [
                'HANDLE_RESIZE',
                'SET_SIDEBAR',
            ])
        }
}
</script>