<template>
  <section class="hero is-medium">
    <div class="hero-body">
      <div class="container has-text-centered">
        <img alt="FLORENSE" width="300" src="../assets/logo.png">
        <h2 class="subtitle">
          {{$t('SOBRE.APRESENTACAO')}}
        </h2>    
        <h2 class="subtitle">
          <span class="tag is-black">{{$t('SOBRE.VERSAO')}} {{config.releaseNumber}}</span>
          <span class="tag is-info">{{config.releaseDate}}</span>
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'usuario'
            ]),
        },
        methods:{
            loadTotalNotificacoesFuncionario() {
                
                this.$http.get('/api/notificacao/retornaTotalNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {                           
                        
                        if(data>0)
                          this.$store.commit('componentes/TOGGLE_MODAL_NOTIFICACAO');
                    })
                    .catch((error) => {                        
                        throw error
                    });
                
            }, 
          checkAbrirGuia(){            
              if(this.usuario.abrirGuiaId!=null && this.usuario.abrirGuiaId!=""
               && this.usuario.abrirUnidadeId!=null  && this.usuario.abrirUnidadeId!=""){                
                const guiaId = this.usuario.abrirGuiaId;
                const unidadeId = this.usuario.abrirUnidadeId;

                this.$store.commit('CLEAR_ABRIRGUIA');
                this.$store.commit('SET_UNIDADE', unidadeId);
                this.$router.push({name: 'guia', params: {id: guiaId}})                
              }

          }                         
        },
        mounted() {                        
            this.loadTotalNotificacoesFuncionario();
            setTimeout(() => {this.checkAbrirGuia()},500);

        },
             
    }
</script>
