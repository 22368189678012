<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <filtros :tipo="'logs'" @filtrar="filtrar($event)" @exportar="exportar($event)" ></filtros>
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>     

            <b-table v-if="lista.length>0" :data="lista"
                    :loading="isLoading"                    
                    striped
                    hoverable                                                            
                    backend-sorting
                    sort-icon-size="is-small">                    

                <template >
                    <b-table-column v-slot="props" field="descricao" :label="$t('SISTEMA.DESC')" >                        
                        <h4>{{props.row.descricao}}</h4>                                                        
                    </b-table-column>

                    <b-table-column v-slot="props" field="usuario" :label="$t('RELATORIO.USUARIO')" >
                        <span><b>{{ props.row.usuarioNome }}</b> ({{ props.row.usuarioId }})</span>
                    </b-table-column>

                    <b-table-column v-slot="props" field="data" :label="$t('SISTEMA.DATA')" >
                        <span>{{ props.row.dataHora | moment('DD/MM/YYYY HH:mm') }}</span>
                    </b-table-column>
                    
                </template>
            </b-table>              
          
        </div>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import filtros from '@/components/filtrosRelatorio.vue';

    export default {
        data() {
            return {
                isLoading: false ,
                lista:[]
            }
        },
        components: {
            titlebar,
            filtros
        },
        computed: {
            titleStack() {
                return [
                    'Relatório',                                        
                    'Sistema',
                    'Logs'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },        
        methods: {

            filtrar(filtros){

                    const params = {                        
                        dataFiltro: (filtros.dataFiltro !=null) ? filtros.dataFiltro : null,                        
                        dataInicial: (filtros.datas[0] !=null)  ? this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') : null,
                        dataFinal:  (filtros.datas[1] !=null) ?  this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss') : null,
                        funcionarioId: (filtros.funcionario?.id != null) ? filtros.funcionario?.id : null ,                        
                    }

                this.carregarLista(params);  

            },

            exportar(filtros){

                    const params = [];

                    if (filtros.dataFiltro != null) params.push(`dataFiltro=${filtros.dataFiltro}`);                    
                    if (filtros.datas[0] != null) params.push(`dataInicial=${this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss')}`);
                    if (filtros.datas[1] != null) params.push(`dataFinal=${this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`);
                    if (filtros.funcionario?.id!= null) params.push(`funcionarioId=${filtros.funcionario?.id}`);                    
                                                                                                                                                                                                                                                            
                    window.open(`/api/relatorio/LogsXLS?${params.join('&')}`,'_blank');
                
            },                              
            carregarLista(params){   
                
                    this.isLoading = true;
                    this.$http.post('/api/relatorio/Logs', params)
                    .then(res => res.data)
                    .then(data => {                         
                            this.lista = data;                           
                    }).finally(() => {
                        this.isLoading = false;
                    })
                    
            },
 
            
        }
    }
</script>