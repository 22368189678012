<template>
    <section>

            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    {{$t('MANUTENCAOLISTAR.PESSOAS')}}
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="card-content is-paddingless">
                <div class="columns">

                    <div class="column ">
                        <div class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.FUNCIONARIO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'funcionarios', query: { procurar: procurar.funcionario }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.funcionario"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'funcionarios' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                  </router-link>
                            </div>
                        </div>
                    </div>     
                    <div class="column">
                            <div  class="panel">
                                <p class="panel-heading background-1">
                                    {{$t('MANUTENCAOLISTAR.UNIDADES')}}
                                </p>
                                <form @submit.stop.prevent="$router.push({ name: 'unidades', query: { procurar: procurar.unidade }})" method="get" class="panel-block">
                                    <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                                v-model="procurar.unidade"
                                                name="procurar" 
                                                icon="magnify"></b-input>
                                </form>
                                <div class="panel-block is-justified-between">
                                    <router-link :to="{ name: 'unidades' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                    </router-link>

                                </div>
                            </div>
                    </div>                      
                
                     <div class="column ">
                        <div  class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.NIVELACESSO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'niveisdeacesso', query: { procurar: procurar.niveldeacesso }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.niveldeacesso"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'niveisdeacesso' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                </router-link>

                            </div>
                        </div>
                    </div>          
                    <div class="column ">        
                    </div>                                                 
                </div>
                 
            </div>

    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                procurar: {}
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',                
                'usuario'   
            ])
        },
        methods: {
            loadTotalNotificacoesFuncionario() {
                
                this.$http.get('/api/notificacao/retornaTotalNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                        this.$store.commit('componentes/SET_TOTAL_NOTIFICACAO',data)
                    })
                    .catch((error) => {                        
                        throw error
                    });
                
            },             
        },
        mounted() {                        
            this.loadTotalNotificacoesFuncionario();                                                                      
        }        
    }
</script>