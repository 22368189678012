<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>

        <b-collapse  class="card has-background-transparent" animation="slide" >
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    Filtro
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>                            
            </div>

            <div  class="box">
                            
                <div class="columns is-multiline">

                    <div class="column is-4 is-full-mobile">
                        <label  class="label">{{$t('FILTROS.EMPRESA')}}</label>
                        <b-select multiple
                                    native-size="4"
                                    expanded
                                    v-model="filtros.unidades">
                                <option style="padding: 0.0em 0.5em;" v-for="(unidade,index) in listaUnidades" :key="index"
                                        :value="unidade.id">
                                    {{ unidade.empresa + "-"+ unidade.idEFinance + "-"+ unidade.nome }}
                                </option>
                            </b-select>
                    </div>  


                    <div class="column is-4 is-full-mobile">
                        <label  class="label">{{$t('FILTROS.STATUS')}}</label>
                        <b-select v-model="filtros.status" :placeholder="$t('FILTROS.STATUS')"  expanded>                        
                        <template v-for="(item, index) in this.StatusAssistencia">
                            <option  selected v-bind:key="index" :value="item.id">
                                {{item.descricao }}
                            </option>
                        </template>
                    </b-select>                        
                    </div>                     
                    

                    <div class="column is-2 ">
                        <label class="label" style="visibility: hidden;">Label Filtro</label>
                        <b-button type="is-primary" native-type="button" icon-left="magnify" @click="pesquisar()">
                            Filtrar
                        </b-button>  
                        
                        <b-button class="ml-2 has-text-white" type="is-warning" native-type="button" icon-left="close-circle" @click="limpar()">
                            Limpar
                        </b-button>                                                  
                                                  
                    </div>



                    <div class="column is-2 ">

                        <span class="is-pulled-right"> 
                                                       
                             <b-button native-type="button" icon-left="refresh" @click="confirmaAtualizacao()"> </b-button>                                                     
                                             
                                                                                
                        </span>
                    </div>            

                </div>
                
                <div class="columns is-multiline">

                    <div  class="column is-4 is-full-mobile">
                        <label  class="label">{{$t('FILTROS.CODIGO')}}</label>
                        <b-input type="number" placeholder="Código do Cliente" v-model="filtros.codigoCliente"  ></b-input>
                    </div> 
                    <div  class="column is-4 is-full-mobile">
                        <label  class="label">{{$t('FILTROS.NOME')}}</label>
                        <b-input type="text" placeholder="Nome do Cliente" v-model="filtros.nomeCliente"  ></b-input>
                    </div>                                                          
                </div>                

            </div>
        
        

        </b-collapse>        
        <br>

        <div  v-for="(item, index) in dados" v-bind:key="index" class="columns is-multiline cardAtendimento" style="margin-bottom: 5px !important;">
                                <div   class="column is-2">
                                    <div >
                                        <b-tag  size="is-large"> 
                                            &nbsp;&nbsp; {{ item.unidadeNome }}
                                        </b-tag> 
                                    </div>      
                                    <span> <b>{{ $moment(item.dataHoraCadastro).format("DD/MM/YYYY HH:mm")}}         </b></span>                                                                                                                                                               
                                </div>
                             
                                <div   class="column is-3">
                                    <small>Cliente</small>
                                    <br />
                                    <span class="button-effect" title="Ver cliente" @click="abrirCliente(item)"> <b>{{ item.codigoCliente +' - ' + item.cliente }} </b></span>                                      
                                </div>      
                                <div   class="column is-2">
                                    <small>Título</small>
                                    <br />
                                    <span> <b>{{ item.titulo }}</b></span>                                                                                                            
                                </div>     
                                <div   class="column is-2">
                                    <small>Elist</small>
                                    <br />
                                    <span v-if="item.elist"> <b>
                                        {{ item.elist  }}   
                                    </b></span> 
                                    <span v-else> <b>
                                        a definir
                                        <a href="#" class="has-text-grey" @click="alterarElist(item.codigoAssistencia)" >
                                                <b-icon  icon="pencil" size="is-small"></b-icon>
                                        </a>                                            

                                    </b></span>                                                                                                              
                                </div>                                  
                                <div   class="column is-2">
                      
                                    <b-button v-if="showButtons" class="ml-2"
                                        @click="AprovacaoSolicitacao(item.codigoAssistencia,item.codigoLoja,item.codigoEmpresa)"  :title="$t('SISTEMA.APROVAR')"                                                                                        
                                        icon-left="check" type="has-text-success" ></b-button>  

                                    <b-button  v-if="showButtons" class="ml-2"
                                        @click="ultimasInformacoes(item.id)"  :title="$t('SISTEMA.HISTORICO')"                                                                                        
                                        icon-left="clock" type="has-text-info" ></b-button>  

                                    <b-button   @click="confirmarInclusaoObservacao(item.codigoAssistencia,item.unidadeGuia,item.codigoEmpresa,item.codigoGuia)" class="ml-2"  
                                        :title="$t('SISTEMA.NOTIFICACAO')" icon-left="comment"></b-button>                                                                                                                                                                
                                                                                                                                                
                                </div> 
   
                                <div   class="column is-6">
                                    <small>Descrição: </small> {{  item.observacao }}
                                </div>                   
                                <div    class="column is-6">
                                    <small >Anexos: </small> 
                                    
                                    <b-tag v-for="(anexo, index) in item.anexos" :key="index"  size="is-small"> 

                                        <a @click="visualizarAnexo(anexo.url)" 
                                        class="is-size-7 text-nowrap"><b>{{ anexo.fileName }}</b></a>

                                    </b-tag> 
                                </div>                                                                                                                                                                                                                                                                       
        </div>          
                            
                            
                                              


    </section>
</template>


<style scoped>

    .cardAtendimento {
        background-color: #fff;
        cursor: pointer;
    }

    .cardAtendimento:hover {
        background-color: hsl(0, 0%, 96%)
    }

    .button-effect:hover {
        background-color: #e9efe9 !important;
    }
    .button-effect{
        cursor: pointer !important;
        color: blue !important;
        text-decoration: underline !important;   
    }
</style>

<script>
    import { mapState, mapGetters } from 'vuex'
    import modalAprovarSolicitacao from '@/components/custofabrica/modalAprovarSolicitacao.vue' 
    import modalListarUltimaObservacoes from '@/components/custofabrica/modalListarUltimaObservacoes.vue' 
    import modalObservacaoGuia from '@/components/custofabrica/modalObservacaoGuia.vue' 

    

    export default {
        data() {
            return {
                isLoading: false,                               
                filtros:{
                    unidades:[],
                    nomeCliente:null,
                    codigoCliente:null,
                    status: 0                   
                },
                listaUnidades:[],                
                dados:[],       
                StatusAssistencia:[ { id:0,descricao: "Pendentes"},{ id:1,descricao: "Aprovados"},{ id:2,descricao: "Reprovados"}],
                showButtons:true                        
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([                                                
                'componentes',
                'usuario'   
            ])
        },
        methods: {
            alterarElist(assistenciaId){
                this.$buefy.dialog.prompt({
                    title: this.$t('CUSTOFABRICA.ATUALIZARELIST'),                    
                    inputAttrs: {
                        type: 'number',                        
                        placeholder: 'Número Elist',
                        value: null
                    }, 
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.$http.get(`/api/dashboard/AtualizarElist?elist=${res}&assistenciaId=${assistenciaId}`)
                        .then((r) => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t("SISTEMA.SALVOSUCESSO"),
                                type: 'is-success'
                            });
                            this.pesquisar();
                        }, error => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Não foi possível salvar o elist",
                                type: 'is-danger'
                            });
                            
                        }
                    )}
                });                
                return true;
            },
            
            isAtendente(){                
                return this.usuario.nivel == 'Atendimento' || this.usuario.nivel == 'ATENDIMENTO';
            },       
            
            confirmarInclusaoObservacao(assistenciaId,unidadeGuia,codigoEmpresa,codigoGuia) {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalObservacaoGuia,
                    props: {
                        assistenciaId: assistenciaId,
                        codigoLoja: unidadeGuia,
                        codigoEmpresa:codigoEmpresa,
                        guiaId: codigoGuia                                         
                    },                   
                    hasModalCard: true,
                    trapFocus: true
                })
            },                  
            ultimasInformacoes(id){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalListarUltimaObservacoes,
                    props: {
                        id: id                   
                    },             
                    hasModalCard: true,
                    trapFocus: true                    
                })

            },               
            limpar(){

                this.filtros.unidades=[];
                this.filtros.nomeCliente=null;
                this.filtros.codigoCliente=null;
                this.filtros.status = 0;               
                this.dados=[];

            },            
            AprovacaoSolicitacao(assistenciaId,codigoLoja,codigoEmpresa){                                
                    
                    this.$buefy.modal.open({
                        parent: this,
                        component: modalAprovarSolicitacao,
                        props: {                        
                            assistenciaId: assistenciaId,
                            codigoLoja: codigoLoja,
                            codigoEmpresa:codigoEmpresa  

                        },
                        events: {
                            loadData: this.atualizar                            
                        },                    
                        hasModalCard: true,
                        trapFocus: true                    
                    }) ;
                    
                },                     
            visualizarAnexo(url){
                window.open(url);
            },
            confirmaAtualizacao(){

                this.$buefy.dialog.confirm({
                    title: 'Atualizar Dados',
                    message: 'Quer atualizar os dados das assistências ? ',
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {
                        this.atualizar();
                    }
                })                

            },

            atualizar(){

                this.isLoading = true;
                this.componentes.sidebarIsOpened = false;
                
                                                                                              
                this.$http.get(`/api/dashboard/executaAtualizacaoAssistenciasCustoFabrica`)
                .then((res) => {
                    return true;
                }).finally(() => { 
                    this.isLoading=false;
                    this.pesquisar();
                    this.dados = [];                                                                                                   
                });                                   
            },            
            pesquisar(){

                this.isLoading = true;
                this.componentes.sidebarIsOpened = false;

                const url = '/api/dashboard/BuscarDadosAssistenciasCustoFabrica';

                this.$http.post(url, this.filtros)
                        .then(res => res.data)
                        .then(data => {  
                            this.dados = data;
                        }).finally(() => {         
                            if(this.filtros.status == 0){
                                this.showButtons=true;
                            }else{
                                this.showButtons=false;
                            }                 
                            this.isLoading=false;                                                                                                           
                        });                                                
            },            
            abrirCliente(item){                
                this.$http.get(`/api/dashboard/abrirCliente?guiaCodigo=${item.codigoGuia}&unicodigo=${item.codigoLoja}&codigoEmpresa=${item.codigoEmpresa}`)
                .then(res => res.data)
                .then(data => { 
                        window.open(data, "_blank");
                    })
            },
            loadListaUnidades() {
                this.$http.get('/api/search/Unidade')
                    .then(m => {
                        this.listaUnidades = m.data
                    })
            },            
                      
        },
        mounted() {      
            this.loadListaUnidades();  
            this.atualizar();                                                                                                             
        }        
    }
</script>