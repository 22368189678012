export default {
    LANGUAGE:{
        NAME:"Portugues"
    },
    SISTEMA:{
        ENVIAR: "Enviar",
        EDITAR: "Editar",
        INCLUIR: "Incluir",
        LISTAR: "Listar",
        SALVAR: "Salvar",
        CANCELAR: "Cancelar",
        CONFIRMAR: "Confirmar",        
        PACIENTE: "Paciente",
        UNIDADE: "Unidade",
        FUNCIONARIO: "Funcionário",        
        SETOR: "Setor",        
        FORNECEDOR: "Fornecedor",        
        SOMENTELEITURA: "Somente leitura",
        NOVO: "Novo",        
        SELECIONEDATAHORA: "Selecione a data e hora",        
        ATENCAO: "Atenção!",
        PROXPAGINA: "Próxima página",
        PAGANTERIOR: "Página anterior",
        PAGINA: "Página",
        PAGATUAL: "Página atual", 
        PROCURAR: "Procurar",
        ERROSALVAR: "Erro ao salvar: ",
        SELECMODEL: "Selecione o Modelo",
        JANEIRO: "Janeiro",
        FEVEREIRO: "Fevereiro",
        MARCO: "Março",
        ABRIL: "Abril",
        MAIO: "Maio",
        JUNHO: "Junho",
        JULHO: "Julho",
        AGOSTO: "Agosto",
        SETEMBRO: "Setembro",
        OUTUBRO: "Outubro",
        NOVEMBRO: "Novembro",
        DEZEMBRO: "Dezembro"
    },

}