<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p v-if="apenasCancelar"  class="modal-card-title">{{$t('CUSTOFABRICA.REPROVAR')}}</p>  
            <p v-else class="modal-card-title">{{$t('CUSTOFABRICA.APROVAR')}}</p>            
        </header>
        <section class="modal-card-body is-paddingless">

  
            <div class="panel">           
                
                <p  v-if="!apenasCancelar" class="panel-block">
                    <b-field :label="$t('CUSTOFABRICA.APROVARSOLICITACAO')">
                    </b-field>
                </p>                
                
                <div v-if="!apenasCancelar"  class="panel-block">                    
                    <b-radio v-model="aprovado"
                        name="aprovacao"                                    
                        native-value="true">
                        {{$t('SISTEMA.SIM')}}
                    </b-radio>                    
                    <b-radio v-model="aprovado"
                        name="aprovacao"                                    
                        native-value="false">
                        {{$t('SISTEMA.NAO')}}
                    </b-radio>                    
                </div>                   

                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>  

                <p class="panel-block">
                    <b-field :label="$t('ANEXOS.ANEXO')">
                    </b-field>
                </p>                
                <div class="panel-block"> 
                    <div v-if="files" class="tags">
                                <span v-for="(file, index) in files"
                                    :key="index" class="tag is-primary" >
                                    {{file.name}}
                                    <button class="delete is-small" type="button" @click="deleteFile(index)">
                                    </button>
                                </span>                                            
                        </div>                       
                    <b-field>
                        <b-upload  
                        accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV,.zip,.ZIP"
                        multiple expanded drag-drop v-model="files" >
                            <section class="section">
                                <div class="content has-text-centered">
                                    <p>
                                        <b-icon icon="upload" size="is-medium"> </b-icon>
                                    </p>
                                    <p>{{$t('ANEXOS.ARRASTEARQUIVOS')}}</p>
                                    <p>({{$t('ANEXOS.APENASFOTOS')}})</p>
                                </div>
                            </section>
                        </b-upload>                                          
                    </b-field>
                       
                </div>                               

                       
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('SISTEMA.CONFIRMAR')}}</b-button>
            <b-button @click="$emit('close');">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'    
    
    export default {
        props: {            
            assistenciaId: Number,
            codigoLoja:Number,
            codigoEmpresa: Number,
            files:[],
            apenasCancelar: {
                type: Boolean,
                default: false
            } 
        },
        data() {
            return {                                               
                salvandoDados :false,                
                observacoes:null,   
                aprovado:true,
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {

            return true;

        },
        methods: {
            
            deleteFile(index){                
                this.files.splice(index, 1)            
            },            

            solicitacaoAprovada() {
                const formData = new FormData()
                formData.append('id', this.assistenciaId);
                formData.append('observacoes', this.observacoes);
                formData.append('aprovado', this.aprovado);
                formData.append('unicodigo', this.codigoLoja);
                formData.append('codigoEmpresa', this.codigoEmpresa);

                if(this.files && this.files.length>0){
                    Array
                    .from(Array(this.files.length).keys())
                    .map(x => {                        
                        formData.append('anexo', this.files[x], this.files[x].name);
                    });
                }


                this.uploadingAnexo = 0;
                this.salvandoDados = true;                    
                this.$http.post('/api/dashboard/executaAprovacaoAssistenciasCustoFabrica', formData, {
                    progress(e) {
                        if (e.lengthComputable) {
                            this.uploadingAnexo = (e.loaded / e.total * 100)
                        }
                    }
                }).then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: "Assistência salva com sucesso",
                        type: 'is-success'
                    })                    
                    this.salvandoDados = false;    
                    this.$emit('close'); 
                    this.$emit('loadData');                                    
                }, error => {
                    this.salvandoDados = false;  
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                });
            },                 
            async confirmar() {


                if(this.apenasCancelar)
                    this.aprovado = false;
                
                this.solicitacaoAprovada();
                
            
            },
            

        }
    }
</script>