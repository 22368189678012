<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>

        <div class="columns">
           
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2">
                        {{$t('RELATORIO.SISTEMA')}}                           
                    </p>

                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr  v-if="isInRole('menu-relatorio-notificacao')" @click="abrirRelatorio('notificacao')"> <td> <span class="is-size-6">Notificações do Sistema</span> </td> </tr>                               
                            <tr  v-if="isInRole('menu-relatorio-logs')" @click="abrirRelatorio('logs')"> <td> <span class="is-size-6">Logs do Sistema</span> </td> </tr>                               
                        </tbody>                                                 
                    </table>
                    
                </div>
            </div>
            <div class="column is-3">
                <div  class="panel">
                    <p class="panel-heading background-2">
                        {{$t('RELATORIO.MANUTENCAO')}}                           
                    </p>

                    <table class="table is-fullwidth is-striped is-hoverable">     
                        <tbody>
                            <tr  v-if="isInRole('menu-relatorio-clientes')" @click="abrirRelatorio('clientesRel')"> <td> <span class="is-size-6">Clientes</span> </td> </tr>                                                           
                            <tr  v-if="isInRole('menu-relatorio-funcionarios')" @click="abrirRelatorio('funcionariosRel')"> <td> <span class="is-size-6">Funcionários</span> </td> </tr>                                                           
                        </tbody>                                                 
                    </table>
                    
                </div>
            </div>            

        </div>
                                 
    </section>
</template>

<style scoped>
tr{
   cursor:pointer !important; 
}

tr:hover {
    background-color: #00d1b2 !important;
}
</style>



<script>
    import { mapState, mapGetters } from 'vuex'        
            
    export default {
        data() {
            return {                                               
                isLoading: false,                                                               
            }
        },
        components: {  
                                  
        },
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'usuario'                
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {            
            unidadeId: function(novaUnidade) {                 
                this.loadAsyncData()                                                
            },
        }, 
        methods: {
            abrirRelatorio(relatorio){
                
                this.$router.push({ name: relatorio});                
            }
        }     
    }
</script>