<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$tc('MODALNOTIFICACAO.TITULO', this.listaNotificacoes.length) }}</p>            
        </header>
        <section class="modal-card-body list is-paddingless is-marginless">
            <div class="list-item p-2"
                 v-for="item in listaNotificacoes" :key="item.id">
                <div class="tags has-addons is-marginless">                    
                    <span class="tag is-primary"> <b>{{ item.unidadeNome}}</b> </span>
                    <span v-if="item.tipo==1" class="tag is-warning"> Alerta </span>
                    <span v-else class="tag is-info">Aviso</span>
                    <span class="tag">{{ item.datahora | moment('DD/MM/YYYY HH:mm:ss') }}</span>
                    <a class="tag is-danger is-delete" @click="apagarNotificacao(item.id);" :title="$t('MODALNOTIFICACAO.APAGAR')"></a>
                </div>
                <p class="is-paddingless has-text-black	">{{ item.descricao }}

                </p>
                  
                <hr class="m-0">
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">{{$t('MODALNOTIFICACAO.FECHAR')}}</button>
            <button v-if="hasNotifications" class="button is-danger" type="button" @click="apagarTodasAsNotificacoes();$parent.close();">{{$t('MODALNOTIFICACAO.APAGARTODAS')}}</button>
        </footer>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        data() {
            return {                                
                listaNotificacoes: [],                                              
            }
        },
        mounted() {
            this.loadNotificacoesFuncionario();              
        },        
        computed: {
            hasNotifications() {
                return this.listaNotificacoes.length > 0;
            },
            ...mapState([                
                'usuario'
            ]),            
        },
        methods: {
            abrirGuia(guiaId,unidadeId,status){

                if(status==0){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId}})
                }else if (status == 1){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,fluxoVenda:true }})
                }else if(status == 5){
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId,fluxoVenda:true }})
                }else{
                    this.$router.push({ name: 'guia', params: { id: guiaId, atualizarUnidadeId:unidadeId}})
                }
                
                this.$parent.close();

            },
            loadNotificacoesFuncionario() {
                
                this.$http.get('/api/notificacao/RetornaNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                        this.listaNotificacoes = data;
                        
                    })
                    .catch((error) => {                        
                        throw error
                    }).finally(() => {
                        this.$store.commit('componentes/SET_TOTAL_NOTIFICACAO',this.listaNotificacoes.length)
                    })  
                
            },             
            async apagarTodasAsNotificacoes() {
                await this.listaNotificacoes.forEach((n) => {

                    this.$http.get('/api/notificacao/DesativarNotificacao?idNotificacao=' + n.id )
                            .then(res => res.data)
                            .then(data => {                           
                            return true;                        
                        })
                        .catch((error) => {                        
                            throw error
                        });

                });
                setTimeout(() => {this.loadNotificacoesFuncionario()},1000);
                
            },
            apagarNotificacao(id) {
                
                this.$http.get('/api/notificacao/DesativarNotificacao?idNotificacao=' + id )
                        .then(res => res.data)
                        .then(data => {                           
                        return true;                        
                    })
                    .catch((error) => {                        
                        throw error
                    }).finally(() => {
                        this.loadNotificacoesFuncionario();
                    })  

            }
        }
    }
</script>