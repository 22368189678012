import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@mdi/font/css/materialdesignicons.css'
import Buefy from 'buefy'
import './assets/scss/app.scss'
import vueMoment from 'vue-moment'
import idle from './plugins/idle'
import WebCam from 'vue-web-cam'
import VueCurrencyFilter from 'vue-currency-filter'
import VueResource from 'vue-resource'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import ptBR from 'vee-validate/dist/locale/pt_BR.json';
import VueTheMask from 'vue-the-mask'
import moment from 'moment';
import VueScrollTo from 'vue-scrollto';
import VueAppInsights from 'vue-application-insights'
import VueGeolocation from 'vue-browser-geolocation';
import i18n from './plugins/i18n'

require('moment/locale/pt-br')

if (process.env.APPINSIGHTS_INSTRUMENTATIONKEY) {
    Vue.use(VueAppInsights, {
        id: process.env.APPINSIGHTS_INSTRUMENTATIONKEY,
        router
    })
}

Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});

Vue.use(VueTheMask)

// config vee-validate
Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: ptBR.messages[rule] // assign message
    });
});
 

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.filter('data', (valor) => {
    if (!valor) return null;
    
    return  moment(valor).format('DD/MM/YYYY');
})

Vue.use(Buefy, {
    defaultDayNames: [
        'Dom',
        'Seg',
        'Ter',
        'Qua',
        'Qui',
        'Sex',
        'Sab'
    ],
    defaultMonthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ],
    defaultDatepickerMobileNative: false,
    defaultDateParser: date => {
        const m = moment(date, 'DD/MM/YYYY')
        return m.isValid() ? m.toDate() : null
    }
})

Vue.use(idle, {
    router,
	store
})
    
Vue.use(vueMoment)
Vue.use(WebCam)
Vue.use(VueCurrencyFilter,
{
    symbol: 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
})
Vue.use(VueResource)
Vue.use(VueGeolocation)

Vue.http.interceptors.push(function (req) {
    req.headers.set('clientVersion', process.env.VUE_APP_VERSION)
    return function (res) {
        if (res.status == 0) {
            store.commit('SET_AUTH', {})
            store.commit('SET_ISSERVEROUT', true)
        } else {
            store.commit('SET_ISSERVEROUT', false)
        }

        if (res.status == 401) {
            store.commit('SET_AUTH', {})
        } 
    }
})

Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app')
