<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field label="Nome"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="40" :has-counter="false"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                                            
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <label class="label">{{$t('FUNCIONARIOCADASTRO.NIVEL')}}</label>
                                    <b-select placeholder="Nível" v-model="model.nivel.id" expanded >
                                        <option v-for="option in model.niveis"
                                                :value="option.id"
                                                :key="option.id">
                                            {{ option.nome }}
                                        </option>
                                    </b-select>
                                </div>   
                               <div  class="column">
                                    <label class="label">{{$t('FUNCIONARIOCADASTRO.USUARIO')}}</label>
                                     <b-field position="" expanded class="fullwidth">
                                        <b-input class="fullwidth" type="text" ref="usuario" v-model="model.usuario" maxlength="40" :has-counter="false" >
                                        </b-input>         
                                    </b-field>                                                                    
                                </div>                                                                                                                          
                            </div>       


                            <div class="columns">
                                <div class="column">
                                     <ValidationProvider rules="required" name="senha" v-slot="{ errors, valid }" v-if="!model.id && carregado">
                                        <b-field :label="$t('FUNCIONARIOCADASTRO.SENHA')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="password" v-model="model.senha" maxlength="40" :has-counter="false"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <ValidationProvider rules="required" name="Confirmação de Senha" v-slot="{ errors, valid }" v-if="!model.id && carregado">
                                        <b-field :label="$t('FUNCIONARIOCADASTRO.CONFIRMARSENHA')"
                                                 :type="{ 'is-danger': getErrosSenha(errors[0]), 'is-success': valid }"
                                                 :message="getMensagemSenha(errors)">
                                            <b-input type="password" v-model="model.novaSenha" maxlength="40" :has-counter="false" @input="validarSenha()"></b-input>
                                        </b-field>
                                    </ValidationProvider>                                       
                                </div>
                            </div>
                          
                            <div class="columns is-multiline is-mobile">
                                <!-- {{model}} -->
                                <div class="column">
                                    <div class="field">
                                        <label class="label">{{$t('FUNCIONARIOCADASTRO.CPF')}}</label>
                                        <div class="control">
                                            <input class="input" type="text" v-model="model.cpf" v-mask="'###.###.###-##'">
                                        </div>
                                    </div>
                                </div>

                                <div class="column">
                                    <ValidationProvider rules="required|email" name="e-mail" v-slot="{ errors, valid }">
                                        <b-field :label="$t('FUNCIONARIOCADASTRO.EMAIL')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors"
                                                 >
                                            <b-input maxlength="50" type="email" v-model="model.email" ></b-input>
                                        </b-field>
                                    </ValidationProvider>                                                  
                                </div>



                                                 
                            </div>                          
                          

                            <div class="columns">
                                <div class="column">
                                    <b-field :label="$t('FUNCIONARIOCADASTRO.FONE')">
                                        <b-input v-model="model.fone" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                    </b-field>
                                </div>
                                <div class="column">

                                    <ValidationProvider rules="required" name="celular" v-slot="{ errors, valid }">
                                        <b-field :label="$t('FUNCIONARIOCADASTRO.CELULAR')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors"
                                                 >
                                            <b-input v-model="model.celular" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                        </b-field>
                                    </ValidationProvider>                                                  

                                </div>        
                                                       
                            </div>

                            <cadastro-items  :label="$t('NIVELDEACESSOCADASTRO.UNIDADESDECADASTRO')" :items.sync="model.unidadesDeAcesso" tabela="unidade" style="width:99%" class="mt-4"/>                                                        
  
                        </article>
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('FUNCIONARIOCADASTRO.FOTO')}}
                                </div>
                                <div>
                                    <div class="panel-block">
                                        <figure class="image">
                                            <img :src="foto" ref="foto">
                                        </figure>
                                    </div>
                                </div>
       
                                <div class="panel-block">
                                    <div class="file has-name fullwidth">
                                        <label class="file-label fullwidth">
                                            <input class="file-input" type="file" name="foto" ref="fileSele"  @change="imageFileSelected($event,'foto')">
                                            <span class="file-cta ">
                                                <span class="file-label">
                                                    <i class="mdi mdi-file"></i> {{$t('FUNCIONARIOCADASTRO.ARQUIVO')}}
                                                </span>
                                            </span>                                            
                                        </label>
                                                
                                    </div>
                                </div>
                                <div class="panel-block">
                                    <div class="field full-width">
                                        <b-switch v-model="model.desativado"  type="is-danger" >
                                            {{$t('SISTEMA.DESATIVADO')}}
                                        </b-switch>
                                    </div>
                                </div>
                            </article>

                            <historico-alteracoes :tabela="'Funcionario'" :chave="model.id"></historico-alteracoes>

                            <article class="panel" v-if="model.id">
                                <div class="panel-heading has-text-centered">
                                    {{$t('FUNCIONARIOCADASTRO.MUDARSENHA')}}
                                </div>
                                <div class="panel-block">
                                    <b-field label="Nova Senha" expanded class="fullwidth">
                                            <b-input type="password" v-model="model.novaSenha" maxlength="40" :has-counter="false" expanded></b-input>
                                    </b-field>
                                </div>
                            </article>
                                                   
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('SISTEMA.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style lang="scss">
.full-width {
    width: 100%;
}

.image {
    width: 100%;
}

.float-right{
    position: absolute !important;
    max-width: 1.5rem;
    max-height: 1.5rem;
    right: 1rem;
}
</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import cadastroItems from '@/components/manutencao/cadastroItems.vue'
    export default {
        data() {
            return {
                model: {
                    id : 0,
                    unidade:{
                        id: null,
                        nome:null,
                    },                                        
                    celular: null,                                        
                    cpf: null,                    
                    desativado: false,
                    email: null,
                    fone: null,
                    niveis: [], //{id: 1, nome: "Administrador"}, {id: 3, nome: "Administrativo"}, {id: 4, nome: "Analista"},…
                    nivel: { id: 0, nome: null} , //{id: 1, nome: "Administrador", acessaSomenteTiposDeLocais: null, administrador: false,…}
                    nome: null,
                    senha: null,
                    usuario: null,
                    novaSenha: null,      
                    unidadesDeAcesso: [],                                             
                },
                foto: null,                                                                      
                salvandoDados: false,                                
                fotoAlteradaAvatar: false,                                             
                erroSenha: null,                
                carregado: false,
                isLoading:false,
            };
        },
        components: {
            titlebar,
            historicoAlteracoes,
            cadastroItems            
        },
        created() {
            const id = this.$route.params?.id ? this.$route.params.id : 0;
            this.isLoading= true;
            this.$http.get('/api/manutencao/funcionario?id='+id+'&unidadeId='+this.unidadeId)
                .then(res => {
                    this.model = res.data;
                    this.model.usuario = res.data.usuario;
                    this.model.dataDeNascimento = this.model.dataDeNascimento ? new Date(this.model.dataDeNascimento) : null;
                    this.model.criarUsuario = false;
                    this.model.novaSenha = null;
                    this.possuiUsuario = true;   
                    
 
                    if (this.model.id == 0 || this.model.id == null){
                        this.model.nivel= {
                            id: this.model.niveis[0]?.id
                        }
                    }

                    if(this.model.desativado==null)
                        this.model.desativado = false;

                    if(!this.model.unidade){
                        this.model.unidade = {
                            id: null,
                            nome: null
                        }

                    }

                    if(!this.model.unidadesDeAcesso)
                        this.model.unidadesDeAcesso = [];

                    this.getImage();
                    this.carregado = true;
                    
                }).finally(() => {
                    this.isLoading= false;
                });

        },
        computed: {
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('FUNCIONARIOCADASTRO.FUNCIONARIO'),
                    this.model.id ? this.model.id :  'novo'
                ]
            },

            ...mapState([
                'unidadeId'                            
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },

        methods: {
            imageFileSelected(e, itemName) {               
                const {name, size} = e.target.files[0];
                let imageData = null;
                if (FileReader && size) {
                    const fr = new FileReader();
                    fr.onload = () => {
                        this['fotoAlterada'+itemName] = true;                        

                        this.fotoAlteradaAvatar = true;
                        
                        imageData = fr.result;
                        this[itemName] = imageData;
                        if (this.$refs[itemName]) this.$refs[itemName].src = imageData;
                    }
                    fr.readAsDataURL(e.target.files[0]);
                }
            },
            validarSenha(){
                this.erroSenha = null;
                if (this.model.id == 0){
                    if (this.model.senha != this.model.novaSenha && this.model.senha?.trim()?.length){
                        this.erroSenha = this.$t('FUNCIONARIOCADASTRO.SENHASIGUAIS');
                    }
                }
                
                return this.erroSenha == null;
            },
            async onSubmit() {    
                
                this.salvandoDados = true;
                if (!this.validarSenha()){
                    return;
                }
                const newItem = {
                    funcionario: Object.assign({},this.model)
                }              

               
                delete newItem.funcionario.foto;
                delete newItem.funcionario.niveis;
                delete newItem.funcionario.senha;
                delete newItem.funcionario.novaSenha;
                delete newItem.funcionario.usuario;


                if (!this.$route.params?.id && this.model.usuario == null){
                    this.model.usuario = this.model.nome;
                }

                if (this.foto && this.fotoAlteradaAvatar){
                    const dadosFotoArray = this.foto.split(',');
                    dadosFotoArray.shift();
                    newItem.foto = dadosFotoArray.join();
                }else{
                    newItem.foto = null;
                }

                if (!newItem.id){
                    newItem.id = 0;
                }     
                
                if(newItem.funcionario.unidade.id == null){
                    newItem.funcionario.unidade.id = 0;
                }
                let novaSenhaString = this.model.senha ? `&novaSenha=${this.model.senha}`:'';                
                const usuarioString = this.model.usuario ? `&nomeUsuario=${this.model.usuario}` : '';
                novaSenhaString += this.model.novaSenha ? `&novaSenhaR=${this.model.novaSenha}` : '';


                if(this.model.unidadesDeAcesso && this.model.unidadesDeAcesso.length>0 ){                    
                    newItem.Unidades=[];
                    this.model.unidadesDeAcesso?.forEach(u => {        
                        newItem.Unidades.push({id: u.id , nome: u.nome })
                    });
                }

                newItem.funcionario.UnidadeDeCadastro = { id : this.unidadeId};

                this.$http.post(`/api/manutencao/funcionario?${novaSenhaString}${usuarioString}`, newItem)
                    .then((res) => {
                        if(res.data != null && res.data.ok != null && res.data.ok == false){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.data.mensagem,
                                type: 'is-warning',
                                queue: false
                            })
                        }
                        this.$router.back()
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                    }).finally(() => {
                        this.salvandoDados = false;
                    });

                    
                 },

            getImage() {
                if (!this.foto){
                    const id = this.$route.params?.id ? this.$route.params.id : 0;
                    this.foto = '/api/manutencao/funcionarioFoto?id=' + id+`&_cl=${new Date().getTime()}`;
                }

                return this.foto;
            },

            getErrosSenha(errors){
                if (this.erroSenha) return true;

                return errors;
            },
            getMensagemSenha(errors){
                if (this.erroSenha){
                    if (errors?.length){
                        return [...errors,this.erroSenha];
                    }else{
                        return [this.erroSenha];
                    }

                }else{
                    return errors;
                }
            },

        }
    }
</script>