<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>


        <b-collapse  class="card has-background-transparent" animation="slide" >
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    Filtro
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>                            
            </div>

            <div  class="box">
                            
                <div class="columns is-multiline">

                    <div class="column is-4 is-full-mobile">
                        <label  class="label">{{$t('FILTROS.EMPRESA')}}</label>
                        <b-select multiple
                                    native-size="4"
                                    expanded
                                    v-model="filtros.unidades">
                                <option style="padding: 0.0em 0.5em;" v-for="(unidade,index) in listaUnidades" :key="index"
                                        :value="unidade.id">
                                    {{ unidade.empresa + "-"+ unidade.idEFinance + "-"+ unidade.nome }}
                                </option>
                            </b-select>
                    </div>  
                    
                    
                    <div  class="column is-4 is-full-mobile">
                        <label  class="label">{{$t('FILTROS.NOME')}}</label>
                        <b-input  placeholder="Nome do Cliente" v-model="filtros.nomeCliente"  ></b-input>
                    </div>     
                            
                    <div class="column is-2 ">
                        <label class="label" style="visibility: hidden;">Label Filtro</label>
                        <b-button type="is-primary" native-type="button" icon-left="magnify" @click="pesquisar()">
                            Filtrar
                        </b-button>  
                        
                        <b-button class="ml-2 has-text-white" type="is-warning" native-type="button" icon-left="close-circle" @click="limpar()">
                            Limpar
                        </b-button>                                                  
                                                  
                    </div>



                    <div class="column is-2 ">

                        <span class="is-pulled-right"> 

                            <b-tooltip   position="is-left" type="is-light"  >                                                            
                                <template  v-slot:content>  
                                    <span v-show="ultimaAtualizacao !=null">
                                        Última atualização em <b>{{ $moment(ultimaAtualizacao).format("DD/MM/YYYY  HH:mm:ss") }} </b>                                                            
                                    </span>

                                        
                                </template>                                                          
                                <b-button native-type="button" icon-left="refresh" @click="confirmaAtualizacao()"> </b-button>                                                     
                            </b-tooltip>                        
                                                                                
                        </span>
                    </div>            

                </div>   
                
                <div class="columns is-multiline">
                    <div  class="column is-4 is-full-mobile">
                        <label  class="label">Processo</label>
                        <b-select  v-model="filtros.processo" placeholder="Processo"  @blur="loadEtapas()" expanded>
                        <option :value="null"></option>                        
                        <option value="Atendimento">Atendimento</option>                        
                        <option value="Vendido">Venda</option>                        
                        <option value="Pós venda">Pós Venda</option>                        
                    </b-select>
                    </div>  
                    <div  class="column is-4 is-full-mobile">
                        <label  class="label">Etapa</label>
                        <b-select  v-model="filtros.etapa" placeholder="Etapa" expanded>
                        <option :value="null"></option>                                                
                        <option v-for="etapa in listaEtapas"
                            :value="etapa.etapa" :key="etapa.etapa">
                            {{ etapa.etapa }}
                        </option>                      
                    </b-select>
                    </div>                                     
                </div>
            </div>
        
        

        </b-collapse>
        

        <div v-if="dados.length>0" class="columns"> 
            <div class="column is-12">
                <div class="panel">
                    <table v-if="visao=='geral'" class="table is-fullwidth is-striped is-hoverable m-0"> 
                        <thead>
                            <th>Etapas</th>                            
                            <th v-for="(c, index) in colunas" v-bind:key="index">{{c}}</th>                            
                        </thead>    
                        <tbody v-if="filtros.processo==null">
                            <tr v-show="dados.filter(x=>x.processo == 'Atendimento').length>0" v-for="(item,index) in dados.filter(x=>x.processo == 'Atendimento')"  v-bind:key="index">
                                <td>{{ item.etapa }}</td>
                                <td class="button-effect" title="Ver lista de clientes" @click="listarAtendimentos(item.etapa)">{{ item.quantidade }}</td>                                
                                <td v-show="dados.filter(x=>x.processo == 'Vendido').length>0"> - </td> 
                                <td v-show="dados.filter(x=>x.processo == 'Pós venda').length>0">  - </td> 
                            </tr>
                            <tr v-show="dados.filter(x=>x.processo == 'Atendimento').length>0" class="has-text-grey has-background-grey-lighter">
                                <td><b>Total</b></td>
                                <td ><b>{{ somaQuantidade('Atendimento') }}</b></td>                                
                                <td v-show="dados.filter(x=>x.processo == 'Vendido').length>0"> - </td> 
                                <td v-show="dados.filter(x=>x.processo == 'Pós venda').length>0">  - </td> 
                            </tr>                                                        
                            <tr v-show="dados.filter(x=>x.processo == 'Vendido').length>0" v-for="(item,index) in dados.filter(x=>x.processo == 'Vendido')" v-bind:key="index" >
                                
                                <td>{{ item.etapa }}</td>
                                <td v-show="dados.filter(x=>x.processo == 'Atendimento').length>0"> - </td> 
                                <td class="button-effect" title="Ver lista de clientes" @click="listarAtendimentos(item.etapa)">{{ item.quantidade }}</td>                                
                                <td v-show="dados.filter(x=>x.processo == 'Pós venda').length>0"> - </td>  
                            </tr> 
                            <tr v-show="dados.filter(x=>x.processo == 'Vendido').length>0" class="has-text-grey has-background-grey-lighter">
                                <td><b>Total</b></td>
                                <td v-show="dados.filter(x=>x.processo == 'Atendimento').length>0"> - </td> 
                                <td><b>{{ somaQuantidade('Vendido') }}</b></td>                                                                                             
                                <td v-show="dados.filter(x=>x.processo == 'Pós venda').length>0"> - </td>  
                            </tr>                             
                            <tr v-show="dados.filter(x=>x.processo == 'Pós venda').length>0" v-for="(item,index) in dados.filter(x=>x.processo == 'Pós venda')" v-bind:key="index" >
                                <td>{{ item.etapa }}</td>
                                <td v-show="dados.filter(x=>x.processo == 'Atendimento').length>0"> - </td> 
                                <td v-show="dados.filter(x=>x.processo == 'Vendido').length>0"> - </td> 
                                <td class="button-effect" title="Ver lista de clientes" @click="listarAtendimentos(item.etapa)">{{ item.quantidade }}</td>                                
                            </tr>
                            <tr v-show="dados.filter(x=>x.processo == 'Pós venda').length>0" class="has-text-grey has-background-grey-lighter">
                                <td><b>Total</b></td>
                                <td v-show="dados.filter(x=>x.processo == 'Atendimento').length>0"> - </td> 
                                <td v-show="dados.filter(x=>x.processo == 'Vendido').length>0"> - </td>  
                                <td><b>{{ somaQuantidade('Pós venda') }}</b></td>                                                                                             
                            </tr> 
                            <tr class="has-text-white-ter has-background-grey">
                                <td><b>Total Geral</b></td>
                                <td><b>{{ somaQuantidade() }}</b></td>                                                                                              
                                <td>  </td>                                                                                                
                                <td>  </td>                                 
                            </tr>                                                                                                                    
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(item, index) in dados.filter(x=>x.processo == filtros.processo)" v-bind:key="index">
                                <td>{{ item.etapa }}</td>
                                <td class="button-effect" title="Ver lista de clientes" @click="listarAtendimentos(item.etapa)">{{ item.quantidade }}</td>                                
                            </tr>
                            <tr class="has-text-white-ter has-background-grey">
                                <td><b>Total Geral</b></td>
                                <td ><b>{{ somaQuantidade(filtros.processo) }}</b></td>                                                                                              
                                <td>  </td>                                                                                                
                                <td>  </td>                                 
                            </tr>                                                                                
                        </tbody>                        
                    </table>
                    <table v-else class="table is-fullwidth is-striped is-hoverable m-0"> 
                        <thead>
                            <th>Empresa </th>                                                        
                            <th>Loja </th>                                                        
                            <th>Cliente </th>                                                                                    
                            <th>Processo </th>                                                                                    
                            <th>Etapa </th>                                                                                    
                        </thead>    
                        <tbody>
                            <tr  v-for="(item, index) in dados" v-bind:key="index">
                                <td >{{ item.empresaCodigo }} </td>                                                                                                                                                                                                      
                                <td >{{ item.unicodigo }} </td>                                                                                                                                                                                                      
                                <td class="button-effect" title="Ver cliente" @click="abrirCliente(item)" >{{ item.cliCodigo +' - ' + item.nomeCliente }} </td>                                                                                                                                                                                                      
                                <td >{{ item.processo }} </td>                                                                                                                                                                                                      
                                <td >{{ item.etapa }} </td>                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                            </tr>
                            
                        </tbody>                       
                    </table>                    
                </div>
            </div>
        </div>
            

    </section>
</template>

<style scoped>

    .button-effect:hover {
        background-color: #e9efe9 !important;
    }
    .button-effect{
        cursor: pointer !important;
        color: blue !important;
        text-decoration: underline !important;   
    }
</style>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isLoading: false,
                ultimaAtualizacao: null,                
                filtros:{
                    unidades:[],
                    nomeCliente:null,
                    processo: null,
                    etapa:null
                },
                listaUnidades:[],
                listaEtapas:[],
                dados:[],
                colunas:[],
                visao: 'geral'
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([                                                
                'componentes'   
            ])
        },
        methods: {
            abrirCliente(item){                
                this.$http.get(`/api/dashboard/abrirCliente?guiaCodigo=${item.guiaCodigo}&unicodigo=${item.idOrigem}&codigoEmpresa=${item.empresaCodigo}`)
                .then(res => res.data)
                .then(data => { 
                        window.open(data, "_blank");
                    })
            },
            listarAtendimentos(etapa){
                this.filtros.etapa = etapa;
                this.visao = "lista";   
                this.dados = [];
                
                setTimeout(() => {this.pesquisar()},500);                
            },  
            loadEtapas(){
                this.dados=[];
                this.colunas = [];
                this.$http.get('/api/search/ListarEtapas?processo='+ (this.filtros.processo ?? ''))
                    .then(m => {
                        this.listaEtapas = m.data
                    })
            },
            loadListaUnidades() {
                this.$http.get('/api/search/Unidade')
                    .then(m => {
                        this.listaUnidades = m.data
                    })
            },            
            loadAsyncData(){
                this.loadUltimaAtualizacao();
            },
            loadUltimaAtualizacao() {
                
                this.$http.get('/api/dashboard/retornaUltimaAtualizacao')
                        .then(res => res.data)
                        .then(data => {                           
                            this.ultimaAtualizacao = data.ultimaAtualizacao;                        
                    })
                    .catch((error) => {                        
                        throw error
                    });
                
            },             

            confirmaAtualizacao(){

                this.$buefy.dialog.confirm({
                    title: 'Atualizar Dados',
                    message: 'Quer atualizar os dados do relatório ? ',
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {
                        this.atualizar();
                    }
                })                

            },

            atualizar(){

                this.isLoading = true;
                this.componentes.sidebarIsOpened = false;
                
                                                                                              
                this.$http.get(`/api/dashboard/executaAtualizacaoAcompanhamento`)
                .then((res) => {
                    return true;
                }).finally(() => { 
                    this.isLoading=false;
                    this.dados = [];                                                                                                   
                });                                   
            },
            limpar(){

                this.filtros.unidades=[];
                this.filtros.nomeCliente=null;
                this.filtros.processo=null;
                this.filtros.etapa=null;                                
                this.dados=[],
                this.colunas=[],
                this.visao= 'geral'                
            },
            pesquisar(){

                this.isLoading = true;
                this.componentes.sidebarIsOpened = false;

                let url = '/api/dashboard/buscarDados';

                if(this.visao == 'lista')
                    url = '/api/dashboard/buscarDadosLista';

                this.$http.post(url, this.filtros)
                        .then(res => res.data)
                        .then(data => {  
                            this.dados = data;
                        }).finally(() => { 
                            this.tratarDados();
                            this.isLoading=false;                                                                               
                        });                                                
            },
            tratarDados(){
                this.colunas=[];
                this.dados.forEach((item) => {
                    if(this.colunas.filter(x=>x == item.processo).length==0){
                        this.colunas.push(item.processo);
                    }                    
                });

            },   
            somaQuantidade(processo){
                let total = 0;
                
                if(processo){
                    this.dados.filter(x=>x.processo==processo).forEach((item) => {
                        total += parseInt(item.quantidade);
                    });                    

                }else{
                    this.dados.forEach((item) => {
                        total += parseInt(item.quantidade);
                    });
                    
                }

                return total;

            }                 
        },
        mounted() {                                      
            this.loadAsyncData();  
            this.loadListaUnidades();  
            this.loadEtapas();                                                                    
        }        
    }
</script>