<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('UNIDADECADASTRO.SALVANDOUNIDADE')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SISTEMA.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="30" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div> 
                    
                
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">

                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    horaRetornoPadrao: '',
                    horaFimExpediente: '',                                         
                    configuracoesNf: [],                    
                    desativado: false,
                },
                salvandoDados: false,
                horaRetornoPadrao: null,
                horaFimExpediente: null
            }
        },
        created() {
            this.$http.get('/api/manutencao/unidade?id=' + this.$route.params.id)
                .then(res => {
                    this.model = res.data;

                    if(this.model.horaRetornoPadrao){
                        this.horaRetornoPadrao = new Date();                    
                        this.horaRetornoPadrao.setHours(this.model.horaRetornoPadrao.split(":")[0]);
                        this.horaRetornoPadrao.setMinutes(this.model.horaRetornoPadrao.split(":")[1]);
                    }

                    if(this.model.horaFimExpediente){
                        this.horaFimExpediente = new Date();
                        this.horaFimExpediente.setHours(this.model.horaFimExpediente.split(":")[0]);
                        this.horaFimExpediente.setMinutes(this.model.horaFimExpediente.split(":")[1]);
                    }
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SISTEMA.MANUTENCAO'),
                    this.$t('UNIDADECADASTRO.UNIDADE'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                if(this.horaRetornoPadrao != null){
                    this.model.horaRetornoPadrao = ("" + this.horaRetornoPadrao.getHours()).padStart(2, '0') + ":" + ("" + this.horaRetornoPadrao.getMinutes()).padStart(2, '0');
                }

                if(this.horaFimExpediente != null){
                    this.model.horaFimExpediente = ("" + this.horaFimExpediente.getHours()).padStart(2, '0') + ":" + ("" + this.horaFimExpediente.getMinutes()).padStart(2, '0');
                }

                this.salvandoDados = true;
                this.$http.post(`/api/manutencao/unidade`, this.model)
                    .then(() => {
                        this.salvandoDados = false
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false
                        throw error
                    });
            },

        }
    }
</script>