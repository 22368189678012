<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALISTARULTIMASOBSERVACOES.ULTIMASOBSERVACOES')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">

                <div class="panel-block">                     
                    <table  v-if="observacoes !=null && observacoes.length>0"   class="table  is-fullwidth is-striped">
                        <tbody>                                                
                            <tr v-for="(obs, index) in observacoes" :key="index">
                                                                       
                                <td>{{ obs.observacao  }}</td>
                                <td><b>{{ obs.usuarioNome }}</b></td>
                                <td>{{ $moment(obs.dataHora).format('DD/MM/YYYY HH:mm:ss') }}</td>                                                                                                                                                              
                            </tr>
                        </tbody>                        
                    </table>  
                </div>                     
                            

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button @click="$emit('close'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            id: Number   
        },
        data() {
            return {                                              
                salvandoDados :false,                
                observacoes:[]                                     
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {                        
            this.loadObservacoes();
        },
        methods: {

            loadObservacoes() {    
                this.salvandoDados = true;
                
                this.$http.get(`/api/dashboard/buscarHistoricoDadosAssistenciasCustoFabrica?assistenciaId=${this.id}`)
                .then(res => res.data)
                .then(data => {

                    if(data && data!=null){

                        this.observacoes = data;                          
                    }
     
                });               
                                                                                         
            },                        

        }
    }
</script>